import { useEffect, useState } from 'react';
import  { UserStore } from './user-store';
import { apiClient } from '../util/apiClient';

console.log(process.env);

export const useSession = () => {
  const [complete, setComplete] = useState(false);

  useEffect(
    function() {
      if (UserStore.getSession()) return setComplete(true);

      apiClient.get(`${process.env.REACT_APP_SERVER_URL}/auth`)
      .then((res) => {
        const { status, data } = res;
        if (status === 401 || status === 403) {
          window.location.href = `${process.env.REACT_APP_SERVER_URL}/login`;
        }

        console.log(data);
        if (!data.logged_in) {
          console.log("move to /login");
          console.log(process.env.REACT_APP_SERVER_URL);
          window.location.href = `${process.env.REACT_APP_SERVER_URL}/login`;
        }
        if (data.user_info) UserStore.setSession(data.user_info);
      })
      .catch((err) => {
        console.error(typeof err, err);
      })
      .finally(() => setComplete(true));
    }
    , []
  );

  return [UserStore.getSession(), complete];
};
