import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import './List.css';
import { apiClient } from '../util/apiClient';

const getUrl = (date, area, theater) => {
  const d = date.replaceAll('-', '')
  return `http://www.cgv.co.kr/theaters/?areacode=${area}&theaterCode=${theater}&date=${d}`;
}

const List = ({ data ,fetchData, screens }) => {
  const [groupedData, setGroupedData] = useState([]);
  useEffect(() => {
    const grouped = data.reduce((acc, item) => {
      const request = { movieName: item.movie, id: item.id, on_off: item.done, areacode: item.areacode, theaterCode: item.theaterCode };
      const dateEntry = acc.find(entry => entry.date === item.date);
      if (dateEntry) {
        const theaterEntry = dateEntry.theaters.find(theater => theater.name === item.theaterName);
        if (theaterEntry) {
          theaterEntry.requests.push(request);
        } else {
          dateEntry.theaters.push({ name: item.theaterName, requests: [request] });
        }
      } else {
        acc.push({
          date: item.date,
          theaters: [{ name: item.theaterName, requests: [request] }]
        });
      }
      return acc;
    }, []);
    const sortedGrouped = _.orderBy(grouped, ['date'], ['desc']);
    setGroupedData(sortedGrouped);
  }, [data]);

  useEffect(() => {

  }, []);

  const isPastDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const compareDate = new Date(date);
    return compareDate < today;
  }

  const handleDelete = (index) => {
    apiClient.delete(`/requests/${index}`)
    .then(response => {
      fetchData();
    });
  }

  const handleOnOffChange = (evt, id) => {
    const newCheckedStatus = evt.target.checked;

    apiClient.put(`/requests/${id}/done`, { checked: newCheckedStatus })
    .then(response => {
      fetchData();
    });
  }

  return (
    <div>
      <h1>Movie Schedule</h1>
      <table className="movie-table">
        <tbody>
          {groupedData.map((entry, dateIndex) => (
            <React.Fragment key={dateIndex}>
              {entry.theaters.map((theater, theaterIndex) => (
                <React.Fragment key={theaterIndex}>
                  {theater.requests.map((request, requestIndex) => (
                    <tr key={requestIndex} className={isPastDate(entry.date) ? 'past-date' : ''}>
                      {theaterIndex === 0 && requestIndex === 0 && (
                        <td rowSpan={entry.theaters.reduce((acc, t) => acc + t.requests.length, 0)}>
                          {entry.date}
                        </td>
                      )}
                      {requestIndex === 0 && (
                        <td rowSpan={theater.requests.length}>
                          <a target="_blank" href={getUrl(entry.date, request.areacode, request.theaterCode)}>
                            {theater.name}
                          </a>
                        </td>
                      )}
                      <td>
                        {request.movieName}
                        <table style={{float:'right'}}><tbody>
                          {_.map(screens[request.id], (item) => 
                            <React.Fragment key={item.id}>
                              <tr><td>[{item.type}]{item.screen} ({item.created_at})</td></tr>
                            </React.Fragment>
                          )}
                        </tbody></table>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={request.on_off}
                          onChange={(evt) => handleOnOffChange(evt, request.id)}
                        />
                      </td>
                      <td><button onClick={() => handleDelete(request.id)}>Delete</button></td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;