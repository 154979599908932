
class UserStoreClass {
  _session = null;

  setSession(session) {
    this._session = session;
  }

  getSession() {
    return this._session;
  }

  clearSession() {
    window.document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    window.location.href = '/';
  }
}

export const UserStore = new UserStoreClass()
