import React, { useState, useEffect } from 'react';
import { apiClient } from '../util/apiClient';
import _ from 'lodash';

const Selector = ({ fetchData }) => {
  const [movies, setMovies] = useState([]);
  const [theaters, setTheaters] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState('');
  const [selectedTheater, setSelectedTheater] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [dates, setDates] = useState([]);

  useEffect(() => {
    // Fetch movie list
    apiClient.get('/movies')
      .then(response => {
        const modifiedData = _.map(response.data, (name) => { const obj = {}; obj.id = name; obj.title = name; return obj });
        setMovies(modifiedData);
      })
      .catch(error => {
        console.error('Error fetching movie list:', error);
      });

    apiClient.get('/theaters')
      .then(response => {
        const data = _.filter(response.data, { 'RegionCode': '01' })[0]['AreaTheaterDetailList'];
        const modifiedData = _.map(data, (d) => { d.id = d.TheaterCode; d.name = d.TheaterName; return d });
        setTheaters(modifiedData);
      })
      .catch(error => {
        console.error('Error fetching theater list:', error);
      });

    // Generate next 4 weeks' dates
    const today = new Date();
    const dateArray = [];
    for (let i = 0; i < 28; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      dateArray.push(date.toISOString().split('T')[0]);
    }
    setDates(dateArray);
  }, []);


  const handleSave = () => {
    const data = {
      movie: selectedMovie,
      areacode: '01',
      theaterCode: selectedTheater,
      date: selectedDate
    };

    apiClient.post('/save', data)
      .then(response => {
        alert('Data saved successfully!');
        fetchData();
      })
      .catch(error => {
        console.error('Error saving data:', error);
      });
  };

  return (
    <div>
      <br/>
      <h1>Movie Selector</h1>
      
      <div>
        <label>Movie: </label>
        <select value={selectedMovie} onChange={e => setSelectedMovie(e.target.value)}>
          <option value="">Select a movie</option>
          {movies.map(movie => (
            <option key={movie.id} value={movie.id}>{movie.title}</option>
          ))}
        </select>
      </div>

      <div>
        <label>Theater: </label>
        <select value={selectedTheater} onChange={e => setSelectedTheater(e.target.value)}>
          <option value="">Select a theater</option>
          {theaters.map(theater => (
            <option key={theater.id} value={theater.id}>{theater.name}</option>
          ))}
        </select>
      </div>

      <div>
        <label>Date: </label>
        <select value={selectedDate} onChange={e => setSelectedDate(e.target.value)}>
          <option value="">Select a date</option>
          {dates.map(date => (
            <option key={date} value={date}>{date}</option>
          ))}
        </select>
      </div>

      <button onClick={handleSave}>Save</button>
    </div>
  );
}

export default Selector;