import React, { useEffect, useState } from 'react';
import { apiClient } from '../util/apiClient';
import './RunTimer.css';

const RunTimer = () => {
  const [time, setTime] = useState();

  const fetchLast = () => {
    apiClient.get('/last')
    .then((response) => {
      setTime(response.data.message);
    });
  }

  useEffect(() => {
    setInterval(fetchLast, 60 * 1000);
    fetchLast();
  }, [])

  return (
    <div className="timer">
      Last update: {time}
    </div>
  );
}

export default RunTimer;
