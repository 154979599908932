// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.movie-table {
  width: 100%;
  border-collapse: collapse;
}

.movie-table th, .movie-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.movie-table th {
  background-color: #f2f2f2;
}

.movie-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.past-date {
  color: #b0b0b0; /* 회색 톤다운 색상 */
}`, "",{"version":3,"sources":["webpack://./src/components/List.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc,EAAE,cAAc;AAChC","sourcesContent":[".movie-table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.movie-table th, .movie-table td {\n  border: 1px solid #dddddd;\n  text-align: left;\n  padding: 8px;\n}\n\n.movie-table th {\n  background-color: #f2f2f2;\n}\n\n.movie-table tr:nth-child(even) {\n  background-color: #f9f9f9;\n}\n\n.past-date {\n  color: #b0b0b0; /* 회색 톤다운 색상 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
