// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.timer {
  position:absolute;
  top:10px;
  right: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/RunTimer.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,QAAQ;EACR,YAAY;AACd","sourcesContent":["div.timer {\n  position:absolute;\n  top:10px;\n  right: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
