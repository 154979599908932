import React, { useState, useEffect } from 'react';
import './App.css';
import _ from 'lodash';
import Selector from './components/Selector';
import List from './components/List';
import { useSession } from './login/use-session';
import { apiClient } from './util/apiClient';
import LogoutButton from './components/LogoutButton';
import RunTimer from './components/RunTimer';

const App = () => {
  const [session, complete] = useSession();
  const [requests, setRequests] = useState([]);
  const [screens, setScreens] = useState([]);
  const [theaters, setTheaters] = useState([]);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const [requestsResponse, theatersResponse, screensResponse] = await Promise.all([
        apiClient.get('/requests'),
        apiClient.get('/theaters'),
        apiClient.get('/screens'),
      ]);
      const requestsData = requestsResponse.data;
      const theatersData = theatersResponse.data;

      const screensData = _.reduce(screensResponse.data, (acc, item) => {
        const { type, screen, created_at } = item;
        const id = String(item.id);
        const request_id = String(item.request_id);
        if (!acc[request_id]) {
          acc[request_id] = [];
        }

        acc[request_id].push({ id, type, screen, created_at });
        return acc;
      }, {});

      setRequests(requestsData);
      setTheaters(theatersData);
      setScreens(screensData);

      const list = _.flatten(_.map(theatersData, (obj) => obj.AreaTheaterDetailList));
      const theaterInfo = list.reduce((acc, item) => {
        _.set(acc, [item.RegionCode, item.TheaterCode], item.TheaterName);
        return acc;
      }, {});

      const updatedRequests = requestsData.map(request => {
        const theaterName = theaterInfo[request.areacode][request.theaterCode];
        return {
          ...request,
          theaterName,
        };
      });

      setData(updatedRequests);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {

  }, [requests, theaters])

  if (!complete) return null;
  if (!session) return <div>로그인이 필요합니다.</div>

  const handleLogout = () => {
    window.location.href = `${process.env.REACT_APP_SERVER_URL}/logout`;
  }

  return (
    <div>
      <Selector fetchData={fetchData} />
      <RunTimer />
      <LogoutButton onLogout={handleLogout} />
      <List data={data} fetchData={fetchData} screens={screens} />
    </div>
  );
};

export default App;

